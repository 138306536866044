/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

button {
  @apply bg-purple-600 text-white text-base font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none;
}
input,
select {
  @apply block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-6;
}
